import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { editIQueryReply, getQueryDetails } from "services/ApiService"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import "./query.css"
import { queryImg } from "utils/APIUrls"

function QueryDetail() {
  var items = [
    {
      queryId: "674fda081c879f7539feb81c",
      queryItemId: "674fda081c879f7539feb81f",
      isRetail: true,
      notes: "testt1 ??",
      category: "triangle",
      material: "Gold",
      quantity: 75,
      length: 9500,
      lengthUnit: "mm",
      width: null,
      widthUnit: "",
      height: null,
      heightUnit: "",
      innerDiameter: 8500,
      innerDiameterUnit: "mm",
      outerDiameter: 9600,
      outerDiameterUnit: "mm",
    },
    {
      queryId: "674fda081c879f7539feb81c",
      queryItemId: "674fda081c879f7539feb81f",
      isRetail: true,
      notes: "testt1 ??",
      category: "triangle",
      material: "Gold",
      quantity: 75,
      length: 9500,
      lengthUnit: "mm",
      width: null,
      widthUnit: "",
      height: null,
      heightUnit: "",
      innerDiameter: 8500,
      innerDiameterUnit: "mm",
      outerDiameter: 9600,
      outerDiameterUnit: "mm",
    },
    {
      queryId: "674fda081c879f7539feb81c",
      queryItemId: "674fda081c879f7539feb81f",
      isRetail: true,
      notes: "testt1 ??",
      category: "triangle",
      material: "Gold",
      quantity: 75,
      length: 9500,
      lengthUnit: "mm",
      width: null,
      widthUnit: "",
      height: null,
      heightUnit: "",
      innerDiameter: 8500,
      innerDiameterUnit: "mm",
      outerDiameter: 9600,
      outerDiameterUnit: "mm",
    },
  ]

  const [data, setData] = useState([])
  const [queryId, setQueryId] = useState(localStorage.getItem("queryId"))
  const [queryType, setQueryType] = useState()
  const [reply, setReply] = useState("")
  const [isView, setIsView] = useState(false)
  const [selectedImage, setSelectedImage] = useState("")
  var navigate = useNavigate()

  var sendReply = () => {
    const reqObj = {
      queryId: queryId,
      reply: reply,
    }

    // console.log("reqObj...", reqObj)

    editIQueryReply(reqObj)
      .then(res => {
        setReply("")
        toast.success("Reply sent successfully!!")
        // console.log("QUery details Items", res.data)
      })
      .catch(err => {
        console.log("err in query detail", err)
      })
  }

  
  var fetchData = () => {
    getQueryDetails(queryId)
      .then(res => {
        setData(res.data)

        setQueryType(res.data[0].queryType)
        setSelectedImage(res.data[0].queryImage)
        console.log("QUery details IMAGE", selectedImage)

      })
      .catch(err => {
        console.log("err in query detail", err)
      })
  }

  useEffect(() => {
    fetchData()
  }, [queryId])

  const closeModal = () => {
    setIsView(false);
    // setSelectedImage("");
  };



  //   console.log("Query id...", queryId)

  document.title = "Query | Stelo - Steel Trading Dashboard"

  return (
    <div className="page-content">
      <ToastContainer />

      {/* window.location.href = "/categories"  */}
      <Breadcrumbs title="Query" breadcrumbItem="Query Details" />
      <button
        className="btn btn-secondary mb-3"
        style={{
          padding: "10px 29px",
          borderRadius: "8px",
          backgroundColor: "#`6c757d",
          border: "none",
          color: "#fff",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
        onMouseOver={e => {
          e.target.style.backgroundColor = "#5a6268"
        }}
        onMouseOut={e => {
          e.target.style.backgroundColor = "#6c757d"
        }}
      >
        Back
      </button>
      {/* Back Button */}

      <div
        className="content-container"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Note Section */}
        <div
          className="note-section mb-4 p-3"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <h5 className="font-weight-bold mb-2">Note:</h5>
          <p style={{ margin: 0, color: "#555" }}>{items[0].notes}</p>
        </div>

        {queryType == 1 ? (
          <div
            className="table-section mb-5"
            style={{
              overflowX: "auto",
            }}
          >
            <table className="table table-hover table-striped align-middle">
              <thead
                className="table-header"
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th>Category</th>
                  <th>Material</th>
                  <th>Inner Diameter</th>
                  <th>Outer Diameter</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Length</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, indx) => (
                  <tr key={indx} style={{ textAlign: "center" }}>
                    <td>{item.category}</td>
                    <td>{item.material}</td>
                    <td>
                      {item.innerDiameter ? `${item.innerDiameter} mm` : "-"}
                    </td>
                    <td>
                      {item.outerDiameter ? `${item.outerDiameter} mm` : "-"}
                    </td>
                    <td>{item.width ? `${item.width} mm` : "-"}</td>
                    <td>{item.height ? `${item.height} mm` : "-"}</td>
                    <td>{item.length ? `${item.length} mm` : "-"}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : queryType == 2 ? (
          <div className="mb-4 d-flex align-items-center justify-content-center">
            <div className="text-center">
              <div className="query-img-div">
                <img
                  src={`${queryImg}${selectedImage}`}
                  className="query-img"
                  alt=""
                />
              </div>
              <button
                className="custom-button view-button"
                onClick={() => {
                  setIsView(true)
                }}
              >
                View
              </button>
              <button className="custom-button download-button">
                Download
              </button>
            </div>
            {isView && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            marginTop:"70px",
            top: "0",
            left: "0",
            width: "100%",
            height: "93%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000",
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "600px",
              textAlign: "center",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <img
              src={`${queryImg}${selectedImage}`}
              alt="Modal"
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
              }}
            />
            <button
              onClick={closeModal}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "8px",
                marginTop: "20px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
          </div>

          
        ) : (
          console.log("Note queryyy")
        )}

        {/* Query Table */}

        {/* Reply Section */}
        <div
          className="reply-section p-4"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <h5 className="mb-3 font-weight-bold">Reply to Query</h5>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <textarea
              className="form-control reply-input mb-3 mb-md-0 me-md-3"
              placeholder="Write your reply here..."
              onChange={e => setReply(e.target.value)}
              value={reply}
              style={{
                resize: "none",
                borderRadius: "8px",
                padding: "10px 15px",
                boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
                border: "1px solid #ddd",
                flex: 1,
              }}
            />
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: "#007bff",
                border: "none",
                padding: "10px 20px",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 123, 255, 0.2)",
                transition: "0.3s",
              }}
              onClick={() => sendReply()}
              onMouseOver={e => {
                e.target.style.backgroundColor = "#0056b3"
                e.target.style.boxShadow = "0 6px 8px rgba(0, 123, 255, 0.4)"
              }}
              onMouseOut={e => {
                e.target.style.backgroundColor = "#007bff"
                e.target.style.boxShadow = "0 4px 6px rgba(0, 123, 255, 0.2)"
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QueryDetail
