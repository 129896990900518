import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { toast, ToastContainer } from "react-toastify"
import { CSSTransition } from "react-transition-group"
import {
  acceptOrder,
  getBulkQueryDetails,
  getCategories,
  getItemsDetails,
  getMaterials,
  getRetailOrderDetails,
  getRetailQueryDetails,
  rejectOrder,
  storeNotification,
} from "services/ApiService"

import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import Loader from "components/Common/Loader"

// import Swal from "sweetalert2"
// import "sweetalert2/dist/sweetalert2.min.css"

function Query() {
  const [retailQuery, setRetailQuery] = useState([])
  const [bulkQuery, setBulkQuery] = useState([])

  const [isRetailQuery, setIsRetailQuery] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  // var navigate = useNavigate()
  // console.log("Bulk Query data....",bulkQuery)

  // var navigate = useNavigate()

  // State to toggle between tabs
  // const [isOpen, setIsOpen] = useEffect(true)
  var fetchRetailQuery = () => {
    getRetailQueryDetails()
      .then(res => {
        // console.log("reponse", res)
        setRetailQuery(res.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error while fetching retail query details:", error)
        toast.error("Failed to fetch retail query details")
      })
  }

  var fetchBulkQuery = () => {
    getBulkQueryDetails()
      .then(res => {
        console.log("bulk query data...", res)
        setBulkQuery(res.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error while fetching bulk query details:", error)
        toast.error("Failed to fetch bulk query details")
      })
  }

  // console.log("data for queryType :- ", data[0].queryType)
  useEffect(() => {
    fetchRetailQuery()
    fetchBulkQuery()
  }, [])

  const handleViewQueryDetails = value => {
    console.log("row.original data :- ", value.queryId)
    localStorage.setItem("queryId", value.queryId)

    // navigate('/query-details')
    console.log("View Query Is calling........")
    window.location.href = "/query-details"
  }

  // handle form data status
  const handleRetailQuery = () => {
    setIsRetailQuery(1)
  }
  // handle Img data status
  const handleBulkQuery = () => {
    setIsRetailQuery(0)
  }

  const queryData = isRetailQuery === 1 ? retailQuery : bulkQuery

  // console.log("Query Data......",queryData)

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Mobile / Email",
        accessor: "contact",
      },

      {
        Header: " Date",
        accessor: "date",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "View ",

        Cell: ({ row }) => (
          <button
            className="btn p-0 text-black d-inline-block font-size-20 text-center border-0"
            style={{
              transition: "none", // Disable any transition effect
              transform: "none",
              borderRadius: "5px",
            }}
            onClick={() => handleViewQueryDetails(row.original)}
          >
            <i className="mdi mdi-eye" style={{ lineHeight: "20px" }}>
              
            </i>
          </button>
        ),
      },
    ]

    // Conditionally add the GST Number column if the active tab is "withGst"

    return baseColumns
  })

  //meta title
  document.title = "Users | Stelo - Steel Trading Dashboard"

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Query" breadcrumbItem="Retail Orders" />
        <ToastContainer />
        <div className="container-fluid">
          <ul className="row m-0 p-0">
            <li
              className={` col-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1 form-tab1 font-size-13 d-flex justify-content-center align-items-center data-tabs   ${
                isRetailQuery === 1 ? "text-white bg-primary" : "text-primary"
              } `}
              onClick={handleRetailQuery}
            >
              Retail Query
            </li>

            <li
              className={`col-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1 form-tab2 font-size-13  d-flex justify-content-center align-items-center data-tabs2     ${
                isRetailQuery === 0 ? "text-white bg-primary" : "text-primary"
              } `}
              onClick={handleBulkQuery}
            >
              Bulk Query
            </li>
          </ul>

          <div className="">
            {isLoading ? (
              <Loader />
            ) : queryData.length > 0 ? (
              <TableContainer
                columns={columns}
                data={queryData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                isPagination={true}
                tableClass="align-middle table-nowrap table-check table table-div"
                theadClass="table-light"
                paginationDiv="col-12"
                pagination="justify-content-center pagination pagination-rounded"
              />
            ) : (
              <div className="text-center mt-4">
                <h5>No Data Found</h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div></div>
    </>
  )
}

Query.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Query

/* 

  isForm ? usersWithoutImgOrder :        : userWithoutImgOrder

*/
